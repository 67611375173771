import styled from "styled-components";

export default styled.div`
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;

  .main-wrapper {
    flex-grow: 1;
    padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;
  }

  &.expand .hxpt {
    display: none;
  }

  & .sidenav-menu {
    width: 200px;
  }

  .layout {
    display: flex;
    height: 100%;
    height: 100vh;
    backdrop-filter: blur(5rem);

    /* on side pane open animation */
    @media (max-width: 1550px) {
      .pane-open {
        padding-left: 70px;
      }
    }

    @media (max-width: 1480px) {
      .pane-open {
        padding-left: 120px;
      }
    }
  }
`;
