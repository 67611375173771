import TextField from "../../Controllers/TextField";
import Select from "../../Controllers/Select/Default";
import DatePicker from "../../Controllers/DatePicker";
import FormattedField from "../../Controllers/FormattedField";
import Password from "../../Controllers/Password";
import { states } from "../../../utils/options";

export const formInit = () => {
  return {
    email: { value: "", message: "", required: true },
    dob: { value: "", message: "", required: true },
    ssn: { value: "", message: "", required: true },
    cardNumber: { value: "", message: "", required: true },
    expirationDate: { value: "", message: "", required: true },
    securityCode: { value: "", message: "", required: true },
    street: { value: "", message: "", required: true },
    city: { value: "", message: "", required: true },
    state: { value: "", message: "", required: true },
    zipCode: { value: "", message: "", required: true },
    phoneNumber: { value: "", message: "", required: true },
    bankAccount: { value: "", message: "", required: true },
    routingNumber: { value: "011401533", message: "", required: true },
    password: { value: "", message: "", required: true },
    rePassword: { value: "", message: "", required: true },
  };
};

export const billingFields = (form) => [
  {
    value: form?.bankAccount?.value,
    name: "bankAccount",
    component: FormattedField,
    label: "Bank Account",
    message: form?.bankAccount?.message,
  },
  {
    value: form?.routingNumber?.value,
    name: "routingNumber",
    component: FormattedField,
    label: "ABA Routing Code",
    format: "#########",
    message: form?.routingNumber?.message,
  },
  {
    value: form?.cardNumber?.value,
    name: "cardNumber",
    component: FormattedField,
    label: "Card Number",
    format: "############",
    placeholder: "############",
    message: form?.cardNumber?.message,
  },
  {
    value: form?.expirationDate?.value,
    name: "expirationDate",
    component: FormattedField,
    label: "Expiration Date",
    format: "##/##",
    placeholder: "MM/YY",
    message: form?.expirationDate?.message,
  },
  {
    value: form?.securityCode?.value,
    name: "securityCode",
    component: FormattedField,
    type: "password",
    label: "Security Code",
    format: "###",
    placeholder: "###",
    message: form?.securityCode?.message,
  },
];

export const addressFields = (form) => [
  {
    value: form?.street?.value,
    name: "street",
    component: TextField,
    label: "Street",
    message: form?.street?.message,
  },
  {
    value: form?.city?.value,
    name: "city",
    component: TextField,
    label: "City",
    message: form?.city?.message,
  },
  {
    value: form?.state?.value,
    name: "state",
    component: Select,
    options: states,
    label: "State",
    message: form?.state?.message,
  },
  {
    value: form?.zipCode?.value,
    name: "zipCode",
    component: TextField,
    label: "Zip Code",
    message: form?.zipCode?.message,
  },
];

export const personalFields = (form) => [
  {
    value: form?.email?.value,
    message: form?.email?.message,
    label: "Email",
    name: "email",
    component: TextField,
  },
  {
    value: form?.dob?.value,
    message: form?.dob?.message,
    label: "Date of Birth",
    name: "dob",
    component: DatePicker,
  },
  {
    value: form?.phoneNumber?.value,
    name: "phoneNumber",
    placeholder: "+1 (***) *** ****",
    message: form?.phoneNumber?.message || "",
    component: FormattedField,
    valid: form?.phoneNumber?.valid || false,
    format: "+1 (###) ### ####",
    label: "Phone number",
    mask: "_",
  },
  {
    value: form.ssn.value,
    message: form.ssn.message,
    name: "ssn",
    label: "SSN",
    format: "###-##-####",
    component: FormattedField,
  },
];

export const passwordField = (form) => [
  {
    value: form.password.value,
    message: form.password.message,
    name: "password",
    component: Password,
    label: "Password",
  },
  {
    value: form.rePassword.value,
    message: form.rePassword.message,
    name: "rePassword",
    component: Password,
    label: "Password",
  },
];
