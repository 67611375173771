import React from "react";
import UploadFileButton from "../Buttons/UploadButton";
import { add, applyLimit, remove } from "../methods";
import {Wrapper, Note} from "../styles";
import ListItem from "./Field";

const Field = ({
  files = [],
  name,
  onChange,
  heading,
  subheading,
  message = "",
}) => {
  const onAddHandler = (e) => {
    const updatedDocumentsList = add(e);
    const updatedFilesList = applyLimit({
      array: [...updatedDocumentsList, ...files],
      limit: files[name]?.limit || 0,
    });
    onChange(updatedFilesList);
  };

  const onRemoveHandler = (id) => {
    const updatedList = remove({ files, id });
    onChange(updatedList);
  };

  return (
    <Wrapper className={`${files.length ? "active" : ""}`}>
      <div className="upload-field">
        <div className="upload-field-title">
          <h3>{heading}</h3>
          <Note className="note">{subheading}</Note>
          {message ? <Note className="note message">{message}</Note> : ""}
        </div>
        <div className="upload-button-wrapper">
          <UploadFileButton name={name} onChange={onAddHandler} />
        </div>
      </div>
      <ul>
        {files.length
          ? files.map((item) => {
              return (
                <li key={item.id}>
                  <ListItem
                    {...item}
                    onRemove={() => onRemoveHandler(item.id)}
                    name={item.name}
                    date={item.lastModifiedDate}
                  />
                </li>
              );
            })
          : ""}
      </ul>
    </Wrapper>
  );
};

export default Field;
