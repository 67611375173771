import { v4 as uuid } from "uuid";

export const colOptions = [
  { value: "remainingBalance", label: "Remaining balance", id: "4" },
  { value: "biweeklyRepayment", label: "Bi-weekly repayment", id: "5" },
  { value: "forgivenessToDate", label: "Forgiveness to date", id: "6" },
  { value: "loanDate", label: "Date loan was taken", id: "7" },
  { value: "repaymentDate", label: "Full repayment due", id: "8" },
];

export const equationOptions = [
  { value: "less", label: "is less then", id: "1" },
  { value: "equal", label: "is equal to", id: "2" },
  { value: "greater", label: "is greater then", id: "3" },
];

export const initForm = () => {
  return [
    {
      colName: "",
      equation: "",
      value: "",
      type: "fields",
      id: uuid(),
    },
  ];
};
