import React from "react";
import styled from "styled-components";

const Wrapper = styled.label`
  color: #28293d;
  margin: 2px 0 8px;
  font-size: 14px;
  display: inline-block;
`;

const Component = ({ label = "", htmlFor = "" }) => {
  return (
    <Wrapper className="field-label" htmlFor={htmlFor}>
      {label}
    </Wrapper>
  );
};

export default Component;
