/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Table from "../../../components/Tables/Collapsible";
import { usePaginatedTable } from "../../../components/Tables/table-hook";
import { RoundedTable } from "../../../components/Tables/styles";
import Button from "../../../components/Buttons/ActionButtons";

const TableControls = ({ rows: Rows, thead: Thead, initStatus = "", api }) => {
  const [open, setOpen] = useState(true);
  const { tableData, fetchTable, pagination } = usePaginatedTable({
    api,
    itemsPerPage: 5,
    payload: { search: "", page: 1, status: initStatus },
  });

  useEffect(() => {
    fetchTable();
  }, []);

  return (
    <div>
      <RoundedTable>
        <Table
          rows={<Rows items={tableData?.items} />}
          thead={<Thead />}
          numberOfRows={tableData?.items?.length}
          pagination={pagination}
          expanded={open}
        />
      </RoundedTable>
      <Button type={open ? "fold" : "expand"} onClick={() => setOpen(!open)} />
    </div>
  );
};

export default TableControls;
