export const terms = [
  { value: "", id: "0", label: "" },
  { value: "6", id: "1", label: "6 months" },
  { value: "12", id: "2", label: "12 months" },
  { value: "18", id: "3", label: "18 months" },
  { value: "24", id: "4", label: "24 months" },
];

export const days = () => {
  const daysArray = [];
  for (let i = 1; i <= 31; i++) {
    let val = String(i);
    if (i < 10) {
      val = `0${val}`;
    }
    daysArray.push({ value: val, id: val, label: val });
  }
  return daysArray;
};

// YEARS OPTIONS FROM 18 YEARS OLD TO 100 YEARS OLD
export const yearsOfAge = () => {
  const yearsArray = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear - 18; i >= currentYear - 100; i--) {
    const val = String(i);
    yearsArray.push({ value: val, id: val, label: val });
  }
  return yearsArray;
};

export const months = [
  { value: "01", id: "1", label: "January" },
  { value: "02", id: "2", label: "February" },
  { value: "03", id: "3", label: "March" },
  { value: "04", id: "4", label: "April" },
  { value: "05", id: "5", label: "May" },
  { value: "06", id: "6", label: "June" },
  { value: "07", id: "7", label: "July" },
  { value: "08", id: "8", label: "August" },
  { value: "09", id: "9", label: "September" },
  { value: "10", id: "10", label: "October" },
  { value: "11", id: "11", label: "November" },
  { value: "12", id: "12", label: "December" },
];

export const years = () => {
  const currentYear = new Date().getFullYear();
  const defaultYearVal = { label: "", value: "", id: "0" };
  const years = Array(10)
    .fill(null)
    .map((_, i) => {
      const value = currentYear + i;
      return {
        label: value,
        value,
        id: value,
      };
    });

  return [defaultYearVal, ...years];
};

export const states = [
  {
    id: "0",
    value: "",
    label: "",
  },
  {
    id: "AK",
    value: "AK",
    label: "AK - Alaska",
  },
  {
    id: "AL",
    value: "AL",
    label: "AL - Alabama",
  },
  {
    id: "AZ",
    value: "AZ",
    label: "AZ - Arizona",
  },
  {
    id: "CA",
    value: "CA",
    label: "CA - California",
  },
  {
    id: "CO",
    value: "CO",
    label: "CO - Colorado",
  },
  {
    id: "DE",
    value: "DE",
    label: "DE - Delaware",
  },
  {
    id: "FL",
    value: "FL",
    label: "FL - Florida",
  },
  {
    id: "HI",
    value: "HI",
    label: "HI - Hawaii",
  },
  {
    id: "IA",
    value: "IA",
    label: "IA - Iowa",
  },
  {
    id: "ID",
    value: "ID",
    label: "ID - Idaho",
  },
  {
    id: "IL",
    value: "IL",
    label: "IL - Illinois",
  },
  {
    id: "IN",
    value: "IN",
    label: "IN - Indiana",
  },
  {
    id: "KS",
    value: "KS",
    label: "KS - Kansas",
  },
  {
    id: "KY",
    value: "KY",
    label: "KY - Kentucky",
  },
  {
    id: "LA",
    value: "LA",
    label: "LA - Louisiana",
  },
  {
    id: "MA",
    value: "MA",
    label: "MA - Massachusetts",
  },
  {
    id: "ME",
    value: "ME",
    label: "ME - Maine",
  },
  {
    id: "MI",
    value: "MI",
    label: "MI - Michigan",
  },
  {
    id: "MN",
    value: "MN",
    label: "MN - Minnesota",
  },
  {
    id: "MO",
    value: "MO",
    label: "MO - Missouri",
  },
  {
    id: "MS",
    value: "MS",
    label: "MS - Mississippi",
  },
  {
    id: "MT",
    value: "MT",
    label: "MT - Montana",
  },
  {
    id: "ND",
    value: "ND",
    label: "ND - North Dakota",
  },
  {
    id: "NE",
    value: "NE",
    label: "NE - Nebraska",
  },
  {
    id: "NH",
    value: "NH",
    label: "NH - New Hampshire",
  },
  {
    id: "NM",
    value: "NM",
    label: "NM - New Mexico",
  },
  {
    id: "NV",
    value: "NV",
    label: "NV - Nevada",
  },
  {
    id: "OH",
    value: "OH",
    label: "OH - Ohio",
  },
  {
    id: "OK",
    value: "OK",
    label: "OK - Oklahoma",
  },
  {
    id: "OR",
    value: "OR",
    label: "OR - Oregon",
  },
  {
    id: "SD",
    value: "SD",
    label: "SD - South Dakota",
  },
  {
    id: "TN",
    value: "TN",
    label: "TN - Tennessee",
  },
  {
    id: "TX",
    value: "TX",
    label: "TX - Texas",
  },
  {
    id: "UT",
    value: "UT",
    label: "UT - Utah",
  },
  {
    id: "WA",
    value: "WA",
    label: "WA - Washington",
  },
  {
    id: "WI",
    value: "WI",
    label: "WI - Wisconsin",
  },
  {
    id: "WY",
    value: "WY",
    label: "WY - Wyoming",
  },
];
