import React from "react";
import styled from "styled-components";
import Pagination from "./Pagination";
import PageOfPages from "./PageNumber";

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagesCounter {
    padding: 10px;
  }
`;

const TableView = ({
  thead,
  rows,
  pagination: { getPageNumber, numberOfItems, itemsPerPage, currentPage },
}) => {
  const renderTable = () => {
    return (
      <table>
        <thead>{thead}</thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };
  return (
    <>
      <div className="table-wrapper">{renderTable()}</div>
      {numberOfItems > itemsPerPage ? (
        <TableFooter>
          <PageOfPages
            numberOfItems={numberOfItems}
            itemsPerPage={itemsPerPage}
            skip={currentPage}
          />
          <Pagination
            data={{ itemsPerPage, numberOfItems }}
            getPageNumber={getPageNumber}
          />
        </TableFooter>
      ) : (
        ""
      )}
    </>
  );
};
export default TableView;
