import React from "react";
import UploadFileButton from "../Buttons/UploadButton";
import RemoveFileBtn from "../Buttons/RemoveButton";
import FileField from "./Field";
import { Wrapper } from "../styles";
import { add, getBase64 } from "../methods";

const Uploader = ({ files, onChange, isFilesError }) => {
  const addFile = async (e, name) => {
    const updatedDocumentsList = add(e);
    const file = updatedDocumentsList[0];
    // ALLOW IMAGES ONLY
    if (file && /(image)/.test(file.type)) {
      const res = await getBase64(file);
      const updatedFiles = { ...files };
      Object.keys(updatedFiles).forEach((key) => {
        if (key === name) {
          updatedFiles[name] = res;
        }
      });
      onChange(updatedFiles);
    }
  };

  const removeFile = (name) => {
    const updatedFiles = { ...files };
    Object.keys(updatedFiles).forEach((key) => {
      if (key === name) {
        updatedFiles[name] = "";
      }
    });
    onChange(updatedFiles);
  };

  const renderButton = ({ name, isFileUploaded }) =>
    isFileUploaded ? (
      <RemoveFileBtn onClick={() => removeFile(name)} />
    ) : (
      <UploadFileButton
        accept="image/png, image/jpeg"
        name={name}
        onChange={(e) => addFile(e, name)}
      />
    );

  const isFrontSideUploaded = !!files?.frontSide;
  const isBackSideUploaded = !!files?.backSide;

  const frontSideLabel = isFrontSideUploaded ? (
    "front-side of the driver's license"
  ) : (
    <span className="placeholder">Front side</span>
  );

  const backSideLabel = isBackSideUploaded ? (
    "back-side of the driver's license"
  ) : (
    <span className="placeholder">Back side</span>
  );

  return (
    <Wrapper>
      <h3 className="heading">Driver&apos;s License</h3>
      <p className={`note ${isFilesError ? "error" : ""}`}>
        Please upload a copy of the front and back of your driver&apos;s
        license.
      </p>
      <ul>
        <FileField
          label={frontSideLabel}
          button={renderButton({
            name: "frontSide",
            isFileUploaded: isFrontSideUploaded,
          })}
        />
        <FileField
          label={backSideLabel}
          button={renderButton({
            name: "backSide",
            isFileUploaded: isBackSideUploaded,
          })}
        />
      </ul>
    </Wrapper>
  );
};

export default Uploader;
