import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/Buttons/Regular";

const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid;
  border-radius: 8px;
  min-width: 500px;
  min-height: 200px;
`;

export const StepOne = ({ moveToNextStep }) => {
  return (
    <Wrapper>
      <Button className="outlined" onClick={moveToNextStep}>
        Next
      </Button>
    </Wrapper>
  );
};

export const StepTwo = ({ moveToNextStep }) => {
  return (
    <Wrapper>
      <Button className="outlined" onClick={moveToNextStep}>
        Next
      </Button>
    </Wrapper>
  );
};

export const StepThree = ({ goToStep }) => {
  return (
    <Wrapper>
      <Button className="outlined" onClick={() => goToStep(1)}>
        Start over
      </Button>
    </Wrapper>
  );
};
