import React from "react";
import styled from "styled-components";
import TimeAgo from "react-timeago";
import Arrow from "@mui/icons-material/TrendingFlatOutlined";
import { formatCurrency } from "../../utils/parsers/currency";
import { dobParser } from "../../utils/parsers/dob";
import { parseFormToRequest } from "../../utils/parsers/form";
import { formatPhoneNumber } from "../../utils/parsers/phone";
import { formatSSN } from "../../utils/parsers/ssn";
import {
  Text as Heading,
  H5 as SectionHeading,
} from "../../components/Typography";
import { getNumeral } from "../../utils/parsers/numbers";
import {
  formStringToDate,
  parseDate,
  timeLeft,
} from "../../utils/parsers/date";
import { formatPropString } from "../../utils/parsers/propsString";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  & .row {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .heading {
    margin: 0;
    font-weight: 600;
  }
`;

const Parsers = () => {
  const amount = "5000";
  const year = "2022";
  const month = "10";
  const day = "20";
  const phone = "3334567890";
  const ssn = "1234564565";
  const date = "11/21/2022";
  const unparsedDate = "11212002";
  const timeAgo = "10/10/2022";
  const propsString = "propsString";

  const form = {
    name: { value: "John", message: "", required: true },
    city: { value: "Santon", message: "", required: true },
  };

  return (
    <Wrapper>
      <SectionHeading>Formatters</SectionHeading>
      <div>
        <Heading className="heading">Currency</Heading>
        <div className="row">
          {amount} <Arrow /> {formatCurrency(amount)}
        </div>
      </div>

      <div>
        <Heading className="heading">Form</Heading>
        <div className="row">
          <pre>{JSON.stringify(form, null, 4)}</pre>
          <Arrow />{" "}
          <pre>{JSON.stringify(parseFormToRequest(form, null, 4))}</pre>
        </div>
      </div>
      <div>
        <div>
          <Heading className="heading">Phone number</Heading>
          <div className="row">
            <pre>{phone}</pre>
            <Arrow />
            <pre>{formatPhoneNumber(phone)}</pre>
          </div>
        </div>
      </div>
      <div>
        <div>
          <Heading className="heading">Social Security Number</Heading>
          <div className="row">
            <pre>{ssn}</pre>
            <Arrow />
            <pre>{formatSSN(ssn)}</pre>
          </div>
        </div>
      </div>
      <div>
        <Heading className="heading">Numerals</Heading>
        <div className="row">
          <pre>1</pre>
          <Arrow />
          <pre>{getNumeral(1)}</pre>
        </div>
        <div className="row">
          <pre>2</pre>
          <Arrow />
          <pre>{getNumeral(2)}</pre>
        </div>
        <div className="row">
          <pre>12</pre>
          <Arrow />
          <pre>{getNumeral(12)}</pre>
        </div>
        <div className="row">
          <pre>23</pre>
          <Arrow />
          <pre>{getNumeral(23)}</pre>
        </div>
      </div>
      <SectionHeading>Date parsers</SectionHeading>
      <div>
        <Heading className="heading">Date of birth</Heading>
        <div className="row">
          year = {year}, month = {month}, day = {day} <Arrow />{" "}
          {JSON.stringify(dobParser(year, month, day))}
        </div>
      </div>
      <div>
        <Heading className="heading">String to date</Heading>
        <div className="row">
          <pre>{date}</pre>
          <Arrow />
          <pre>{JSON.stringify(formStringToDate(date, null, 4))}</pre>
        </div>
      </div>
      <div>
        <Heading className="heading">Parse date</Heading>
        <div className="row">
          <pre>{unparsedDate}</pre>
          <Arrow />
          <pre>{parseDate(unparsedDate)}</pre>
        </div>
      </div>
      <div>
        <Heading className="heading">Time left</Heading>
        <div className="row">
          <pre>{date}</pre>
          <Arrow />
          <pre>{JSON.stringify(timeLeft(date), null, 4)}</pre>
        </div>
      </div>
      <div>
        <Heading className="heading">Time ago</Heading>
        <div className="row">
          {timeAgo}
          <Arrow />
          <TimeAgo date={new Date(timeAgo)} />
        </div>
      </div>
      <div>
        <Heading className="heading">Props string</Heading>
        <div className="row">
          {propsString}
          <Arrow />
          {formatPropString(propsString)}
        </div>
      </div>
    </Wrapper>
  );
};

export default Parsers;
