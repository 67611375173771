import React, { useState } from "react";
import styled from "styled-components";
import Error from "../Elements/FieldError";
import ShowPassword from "@mui/icons-material/VisibilityOutlined";
import HidePassword from "@mui/icons-material/VisibilityOffOutlined";
import { DynamicLabelInput } from "../styles";
import Label from "../Elements/FieldLabel";

const Icon = styled.button`
  position: absolute;
  top: 0;
  right: 6px;
  cursor: pointer;
  border: none;
  background: transparent;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const InputField = ({
  value,
  label = "",
  disabled = false,
  message = "",
  onChange,
  name,
  placeholder = " ",
}) => {
  const [show, setShow] = useState(false);
  const showPasswordHandler = () => {
    setShow(!show);
  };
  const error = !!message;
  return (
    <DynamicLabelInput
      className={`textField ${
        error ? "error" : ""
      } textField-placeholder-label ${value ? "isFilled" : ""}`}
    >
      <input
        className="input-password"
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        type={show ? "text" : "password"}
        name={name}
        onChange={onChange}
      />
      {label ? <Label label={label} /> : ""}
      <Icon className="icon" type="button" onClick={showPasswordHandler}>
        {show ? <ShowPassword /> : <HidePassword />}
      </Icon>

      <Error message={message} />
    </DynamicLabelInput>
  );
};

export default InputField;
