import React from "react";
import styled from "styled-components";
import Dialog from '@mui/material/Dialog';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 0 24px;
`;

const Popup = styled(Dialog)`
  .MuiDialog-container .MuiDialog-paperWidthSm {
    max-width: 800px;
    margin-top: 10%;
  }
  .MuiDialog-scrollPaper {
    align-items: start;
  }

  @media screen and (max-width: 600px) {
    .MuiDialog-container .MuiDialog-paperWidthSm {
      border-radius: 0;
    }

    .MuiDialog-paper {
      margin: 0;
    }
  }
`;

const DialogTitle = ({ onClose, children }) => {
  return (
    <TitleWrapper>
      <h4> {children}</h4>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </TitleWrapper>
  );
};

const ModalDialog = ({
  children,
  open,
  handleClose,
  showHideButton = false,
  modalTitle,
}) => {
  return (
    <Popup
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {modalTitle || showHideButton ? (
        <DialogTitle onClose={handleClose}>{modalTitle}</DialogTitle>
      ) : (
        ""
      )}
      {children}
    </Popup>
  );
};

export default ModalDialog;
