import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    & button {
      background: transparent;
      border: none;
    }
  }
`;

const Tabs = ({ onChange, buttons, activeTab }) => {
  return (
    <Wrapper className="tabs">
      <ul className="tabs-buttons">
        {buttons.map((tab) => {
          const Component = tab.component;
          const active = tab.value === activeTab;
          return (
            <li
              className={`tabs-button ${active ? "active" : ""}`}
              key={tab.value}
            >
              <button type="button" onClick={() => onChange(tab.value)}>
                <Component {...tab} active={active} />
              </button>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default Tabs;
