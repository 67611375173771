import React, { useState } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { initForm } from "./config";
import Row from "./Row";
import { Button } from "../../Buttons/Regular";
import TickIcon from "@mui/icons-material/DoneOutlined";
import Toggle from "../../Buttons/Toggle/Type-1";
import { onChangeFormHandler, onToggle, onAddCondition } from "./actions";

const Wrapper = styled.form`
  .content-wrapper {
    min-width: 300px;

    & .heading,
    & .subheading {
      color: var(--color-gray-5);
    }

    padding: 0 64px 64px;
    &,
    .fields {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .fields {
      max-height: 50vh;
      overflow: auto;
    }

    & .toggle-btn {
      align-self: center;

      & button {
        width: 80px;
      }
    }
    & .modal-footer {
      display: flex;
      justify-content: space-between;
      button[type="submit"] {
        width: 160px;
      }
      & .tick-icon {
        width: 16px;
        & path {
          fill: #fff;
        }
      }
    }
  }
`;

const FormContent = () => {
  const [form, setForm] = useState(initForm());
  const [payload, setPayload] = useState(null);

  const onChangeHandler = (e, rowId) => {
    const updatedForm = onChangeFormHandler({ e, rowId, form });
    setForm(updatedForm);
  };

  const onToggleHandler = (value, rowId) => {
    const updatedForm = onToggle({ value, rowId, form });
    setForm(updatedForm);
  };

  const onAddConditionHandler = () => {
    const updatedForm = onAddCondition(form);
    setForm(updatedForm);
  };

  const onRemoveConditionHandler = (id) => {
    const updatedForm = form
      .filter((item) => item.id !== id)
      .filter((item) => item.rowId !== id);
    setForm(updatedForm);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setPayload(form);
  };

  return (
    <Wrapper onSubmit={onSubmitHandler}>
      <div className="content-wrapper">
        <div className="fields">
          {form.map((row, i) => {
            if (row.type === "condition") {
              return (
                <Toggle
                  onChange={(e) => onToggleHandler(e, row.id)}
                  labelLeft="AND"
                  labelRight="OR"
                />
              );
            }
            return (
              <Row
                {...row}
                showRemoveBtn={i !== 0}
                onChange={(e) => onChangeHandler(e, row.id)}
                onRemoveHandler={() => onRemoveConditionHandler(row.id)}
                key={row.id}
              />
            );
          })}
        </div>
        {form.length < 9 && (
          <div>
            <Button
              type="button"
              className="outlined"
              onClick={onAddConditionHandler}
            >
              <AddIcon /> Add a condition
            </Button>
          </div>
        )}
        <div className="modal-footer">
          <Button type="submit" className="contained">
            Apply filters <TickIcon className="tick-icon" />
          </Button>
        </div>
      </div>
      {payload && (
        <div>
          OUTPUT:
          <pre>{JSON.stringify(payload, null, 4)}</pre>
        </div>
      )}
    </Wrapper>
  );
};

export default FormContent;
