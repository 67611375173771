import { StepOne, StepTwo, StepThree } from "./Content";

export const steps = () => [
  {
    number: 1,
    name: "Step one",
    active: false,
    completed: false,
    component: StepOne,
  },
  {
    number: 2,
    name: "Step two",
    active: false,
    completed: false,
    component: StepTwo,
  },
  {
    number: 3,
    name: "Step three",
    active: false,
    completed: false,
    component: StepThree,
  },
];
