import React from "react";
import styled from "styled-components";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import DeleteButton from "../Buttons/RemoveButton";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  &:not(:first-child) {
    margin-top: 12px;
  }

  .file {
    &-info-wrapper {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    &-name {
      color: #1e84be;
      margin-bottom: 2px;
    }

    &-date {
      color: #b7b7b7;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .remove-btn {
    border: none;
    background: transparent;
  }
`;

const ListItem = ({ onRemove, name = "" }) => {
  return (
    <Wrapper>
      <div className="file-info-wrapper">
        <ImageIcon />
        {name}
      </div>
      <DeleteButton onClick={onRemove} />
    </Wrapper>
  );
};

export default ListItem;
