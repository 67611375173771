import React from "react";
import styled from "styled-components";
import Table from "./Simple";
import TableWithFilters from "./WithFilters";
import Rows from "./Rows";
import Thead from "./Thead";
import { fetchTable } from "./mockApi";
import { filters } from "./WithFilters/Filters/confg";
import CollapsibleTable from "./Collapsible";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const Buttons = () => {
  return (
    <Wrapper>
      <section>
        <Table rows={Rows} thead={Thead} api={fetchTable} />
      </section>
      <section>
        <TableWithFilters
          filtersInit={filters}
          rows={Rows}
          thead={Thead}
          api={fetchTable}
          initStatus="pending"
        />
      </section>
      <section>
        <CollapsibleTable rows={Rows} thead={Thead} api={fetchTable} />
      </section>
    </Wrapper>
  );
};

export default Buttons;
