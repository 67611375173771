/*eslint-disable*/
import React, { useEffect } from "react";
import Table from "../../../components/Tables/Paginated";
import { usePaginatedTable } from "../../../components/Tables/table-hook";
import { RoundedTable } from "../../../components/Tables/styles";

const TableControls = ({ rows: Rows, thead: Thead, initStatus = "", api }) => {
  const { tableData, fetchTable, pagination } = usePaginatedTable({
    api,
    itemsPerPage: 5,
    payload: { search: "", page: 1, status: initStatus },
  });

  useEffect(() => {
    fetchTable();
  }, []);

  return (
    <RoundedTable>
      <Table
        rows={<Rows items={tableData?.items} />}
        thead={<Thead />}
        pagination={pagination}
      />
    </RoundedTable>
  );
};

export default TableControls;
