// ENUMERATE DAYS: 1ST, 2ND, 24TH
export const getNumeral = (value) => {
  if (["11", "12", "13"].includes(value.toString())) {
    return `${value}th`;
  }
  const number = value.toString().split("");
  switch (number[number.length - 1]) {
    case "1":
      return `${value}st`;
    case "2":
      return `${value}nd`;
    case "3":
      return `${value}rd`;
    default:
      return `${value}th`;
  }
};
