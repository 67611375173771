import React, { useState } from "react";
import { Cell } from "../../components/Tables/styles";
import ActionBtn from "../../components/Buttons/ActionButtons";
import Modal from "../../components/Modal";
import Form from "../form";

const Actions = ({ formData, cb }) => {
  const [open, setOpen] = useState(false);
  return (
    <Cell>
      <ActionBtn onClick={() => setOpen(true)} type="edit" />
      <Modal open={open} handleClose={() => setOpen(false)}>
        <Form state={formData} handleClose={() => setOpen(false)} cb={cb}/>
      </Modal>
    </Cell>
  );
};

const Rows = ({ items = [], cb }) => {
  return items.map(({ name = "", email = "", status = "", date = "" }) => {
    return (
      <tr key={email}>
        <td>
          <Cell>{name}</Cell>
        </td>
        <td>
          <Cell>{email}</Cell>
        </td>
        <td>
          <Cell>{status}</Cell>
        </td>
        <td>
          <Cell>{date}</Cell>
        </td>
        <td>
          <Actions formData={{ name, email, status, date }} cb={cb} />
        </td>
      </tr>
    );
  });
};

export default Rows;
