/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { ModalPosition, ModalContainer, Backdrop } from "./styles";

const Modal = ({ ui, onClose, buttonRef }) => {
  const [position, setPosition] = useState(null);
  const [overflow, setOverflow] = useState(0);
  const [sides, setSides] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    // CLOSE DROP MENU ON SCROLL
    const onScroll = () => {
      onClose();
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const overflowParams = { x: 0, y: 0 }; // ADJUST DROPDOWN POSITION IF IT OVERFLOWS SCREEN SIDES

    // POSITION THE DROP MENU
    const menu = ref?.current;
    const menuParams = menu.getBoundingClientRect(); // dropdown position
    const buttonPosition = buttonRef?.getClientRects()[0];

    // IF THE DROPDOWN OVERFLOWS THE BOTTOM OF THE SCREEN
    if (menuParams.height) {
      const heightOverflow =
        window.innerHeight - (buttonPosition.top + menuParams?.bottom);
      overflowParams.y = heightOverflow < 0 ? "100%" : 0;
    }

    // IF THE DROPDOWN OVERFLOWS THE RIGHT BORDER OF THE SCREEN
    const sideOverflow = window.innerWidth - buttonPosition.left;
    if (sideOverflow < menuParams.width) {
      overflowParams.x = sideOverflow > 0 ? `-${sideOverflow}px` : 0;
    }
    console.log("window.innerWidth ", window.innerWidth);
    console.log("buttonPosition ", buttonPosition);
    console.log("menuParams.width ", menuParams.width);
    console.log("sideOverflow ", sideOverflow);
    setPosition(buttonPosition);
    setOverflow(overflowParams);
  }, []);

  const top = position?.bottom || 0;
  const left = position?.left || 0;

  return (
    <ModalContainer>
      <ModalPosition
        className={`${overflow < 0 ? "goUp" : ""} ${position ? "show" : ""}`}
        ref={ref}
        style={{
          top: `${top}px`,
          left: `${left}px`,
          transform: `translate(${overflow.x}, ${overflow.y})`,
        }}
      >
        {ui}
      </ModalPosition>
      <Backdrop onMouseDown={onClose} />
    </ModalContainer>
  );
};

const Portal = ({ children, open, onClose, buttonRef }) => {
  return open
    ? ReactDOM.createPortal(
        <Modal ui={children} onClose={onClose} buttonRef={buttonRef} />,
        document.body
      )
    : null;
};

export default Portal;
