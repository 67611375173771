import React from "react";
import styled from "styled-components";
import CurrencyFormat from "react-number-format";
import Error from "../Elements/FieldError";
import { InputWrapper } from "../styles";
import Label from "../Elements/FieldLabel";

const Wrapper = styled(InputWrapper)`
  position: relative;
  & .routing-text-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 26px;
    z-index: 2;
    font-size: inherit;
    pointer-events: none;

    .dash {
      padding: 0 5px;
      font-size: inherit;
    }
  }

  & .input-wrapper input {
    padding-left: 104px;
    width: 160px;
  }
`;

const Prefix = () => {
  return (
    <div className="routing-text-wrapper">
      <span>xxxx</span>
      <span className="dash">-</span>
      <span>xx</span>
      <span className="dash">-</span>
    </div>
  );
};

const FormattedField = ({
  isAllowed,
  mask,
  autoFocus = false,
  value = "",
  displayType,
  label = "",
  valid,
  disabled = false,
  message = "",
  onChange,
  name = "field",
  placeholder = "",
  ...props
}) => {
  const isValid = !!(valid && !message);
  const error = !!message;
  return (
    <Wrapper
      className={`textField ${error ? "error" : ""} ${value ? "isFilled" : ""}`}
    >
      {label ? <Label label={label} /> : ""}
      <div
        style={{ position: "relative" }}
        className={`input-wrapper ${isValid ? "isValid" : ""}`}
      >
        <CurrencyFormat
          displayType={displayType}
          isAllowed={isAllowed}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          onValueChange={(values) => {
            onChange({
              target: {
                name,
                value: values.value,
              },
            });
          }}
          mask={mask}
          format="####"
          autoFocus={autoFocus}
          {...props}
        />
        <Prefix />
      </div>
      <Error message={message} />
    </Wrapper>
  );
};

export default FormattedField;
