import React from "react";
import styled from "styled-components";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { button } from "../../components/Buttons/Regular";

const Styled = styled.div`
  ${button}
  display: flex;
  column-gap: 10px;
`;

const Button = ({ label, icon: Icon, active = false }) => {
  return (
    <Styled className={`${active ? "contained" : "outlined"}`}>
      <Icon />
      {label}
    </Styled>
  );
};

export const config = [
  { value: "1", label: "tab 1", icon: AttachFileIcon, component: Button },
  { value: "2", label: "tab 2", icon: AttachFileIcon, component: Button },
  { value: "3", label: "tab 3", icon: AttachFileIcon, component: Button },
  { value: "4", label: "tab 4", icon: AttachFileIcon, component: Button },
  { value: "5", label: "tab 5", icon: AttachFileIcon, component: Button },
];

const Wrapper = styled.div``;

const renderContent = (tab) => {
  switch (tab) {
    case "1":
      return <div>tab 1 content</div>;
    case "2":
      return <div>tab 2 content</div>;
    case "3":
      return <div>tab 3 content</div>;
    case "4":
      return <div>tab 4 content</div>;
    case "5":
      return <div>tab 5 content</div>;
    default:
      return <div>tab 1 content</div>;
  }
};

const Content = ({ activeTab }) => {
  return <Wrapper>{renderContent(activeTab)}</Wrapper>;
};

export default Content;
