import React from "react";
import styled from "styled-components";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { button } from "../../components/Buttons/Regular";
import UserForm from "../../components/Forms/UserForm";
import FilterForm from "../../components/Forms/FilterForm";

const Styled = styled.div`
  ${button}
  display: flex;
  column-gap: 10px;
`;

const Button = ({ label, icon: Icon, active = false }) => {
  return (
    <Styled className={`${active ? "contained" : "outlined"}`}>
      <Icon />
      {label}
    </Styled>
  );
};

export const config = [
  { value: "1", label: "User form", icon: AttachFileIcon, component: Button },
  { value: "2", label: "Filter form", icon: AttachFileIcon, component: Button },
];

const renderContent = (tab) => {
  switch (tab) {
    case "1":
      return <UserForm />;
    case "2":
      return <FilterForm />;
    default:
      return <></>;
  }
};

const Content = ({ activeTab }) => {
  return <div>{renderContent(activeTab)}</div>;
};

export default Content;
