import React from "react";
import CurrencyFormat from "react-number-format";

export const formatPhoneNumber = (phoneNumber) => {
  if (typeof phoneNumber === "string") {
    return (
      <CurrencyFormat
        value={phoneNumber}
        displayType="text"
        format="+1 (###) ### ####"
      />
    );
  }
  return phoneNumber;
};
