/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Table from "../../../components/Tables/Paginated";
import { usePaginatedTable } from "../../../components/Tables/table-hook";
import { RoundedTable } from "../../../components/Tables/styles";
import Filters from "./Filters";
import SearchInput from "../../../components/Controllers/Search/Debounce";

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
`;

const TableControls = ({ rows: Rows, thead: Thead, initStatus = "", filtersInit = [], api }) => {
  const [status, setStatus] = useState(initStatus);
  const [filters, setFilters] = useState([...filtersInit]);
  const [search, setSearch] = useState("");
  const { tableData, fetchTable, pagination } = usePaginatedTable({
    api,
    itemsPerPage: 5,
    payload: { search: "", page: 1, status },
  });

  useEffect(() => {
    fetchTable({ search, status });
  }, [search, status]);

  // TABLE FILTERS
  const filterHandler = (selectedTab) => {
    // HIGHTLIGHT THE ACTIVE TAB
    const updatedFilters = filters.map((item) =>
      item.value === selectedTab
        ? { ...item, active: true }
        : { ...item, active: false }
    );
    // SEE IF THE CURRENT STATUS HAS CHANGED
    const updatedStatus = updatedFilters.find((item) => item.active);
    if (updatedStatus?.value && status !== updatedStatus?.value) {
      setStatus(updatedStatus?.value);
    }
    // SET A CALLBACK TO SHOW WHICH FILTER IS SELECTED
    setFilters(updatedFilters);
    if (typeof activeFilter === "function") {
      activeFilter(updatedFilters);
    }
  };

  const searchHandler = (value) => setSearch(value);

  return (
    <div>
      <Navigation>
        {filters.length ? (
          <Filters items={filters} onChange={filterHandler} />
        ) : (
          <div />
        )}
        <SearchInput searchHandler={searchHandler} />
      </Navigation>

      <RoundedTable>
        <Table
          rows={<Rows items={tableData?.items} />}
          thead={<Thead />}
          pagination={pagination}
        />
      </RoundedTable>
    </div>
  );
};

export default TableControls;
