import React from "react";
import axios from "axios";
import { Button } from "../Buttons/Regular";
import { download } from "./utils";

export const downloadPdf = async (path) => {
  try {
    const response = await axios.get(path, { responseType: "blob" });

    download(response.data);
  } catch (error) {
    console.log(error);
  }
};

const DownloadPdf = () => {
  const path =
    "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf";
  return <Button onClick={() => downloadPdf(path)}>Download pdf by url</Button>;
};

export default DownloadPdf;
