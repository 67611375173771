import React from "react";
import Error from "../Elements/FieldError";
import { DynamicLabelInput } from "../styles";
import Label from "../Elements/FieldLabel";

const InputField = ({
  value = "",
  label = "",
  valid,
  type = "text",
  disabled = false,
  message = "",
  onChange,
  name = "field",
  placeholder = " ",
}) => {
  const error = !!message;
  return (
    <DynamicLabelInput
      className={`textField ${
        error ? "error" : ""
      } textField-placeholder-label ${value ? "isFilled" : ""}`}
      valid={!error && valid}
    >
      <input
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={onChange}
      />
      {label ? <Label label={label} /> : ""}

      <Error message={message} />
    </DynamicLabelInput>
  );
};

export default InputField;
