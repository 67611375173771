import moment from "moment";
import { dateFormat } from "../variables";

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const mo = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// 1 MONTH, 2 MONTHS
export const getMonthNumeral = (number) => {
  if (number === 1) {
    return `${number} month`;
  }
  return `${number} months`;
};

// STRING TO DATE
export const formStringToDate = (date) => {
  if (typeof date === "string") {
    return moment(date, dateFormat);
  }

  return date;
};

// parse input date in format 11122020 to format 11/12/2000
export const parseDate = (date) => {
  const newDate = date.replace("/", "").trim();
  const month = newDate.slice(0, 2);
  const day = newDate.slice(2, 4);
  const year = newDate.slice(4, 8);
  return `${month}/${day}/${year}`;
};

// TIME LEFT
export const timeLeft = (date) => {
  const createdDate = moment(date);
  const currentTime = moment();
  const expiryDate = createdDate.clone().add(2, "days");
  if (currentTime.isBefore(expiryDate)) {
    const timeLeft = moment.duration(expiryDate.diff(currentTime));
    const daysLeft = timeLeft.days();
    const hoursLeft = timeLeft.hours();
    const minutesLeft = timeLeft.minutes();
    const secondsLeft = timeLeft.seconds();
    if (daysLeft || minutesLeft || hoursLeft)
      return {
        days: daysLeft,
        hours: hoursLeft,
        seconds: secondsLeft,
        minutes: minutesLeft,
        expired: false,
      };
  }

  return {
    days: 0,
    hours: 0,
    seconds: 0,
    minutes: 0,
    expired: true,
  };
};
