import React, { useState } from "react";
import styled from "styled-components";
import FileUploader from "../../components/Uploaders/FileUploader";
import DriverLicenseUploader from "../../components/Uploaders/DriverLicense";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const Uploaders = () => {
  const [files, setFiles] = useState([]);
  const [license, setLicense] = useState({ frontSide: "", backSide: "" });

  const onChangeFiles = (newFiles) => {
    setFiles(newFiles);
  };

  const onChangeLicenseHandler = (newFiles) => {
    setLicense(newFiles);
  };

  return (
    <Wrapper>
      <section>
        <FileUploader
          heading="Upload Documents"
          subheading="upload as many documents as you can"
          files={files}
          name="documents"
          onChange={onChangeFiles}
        />
      </section>
      <section>
        <DriverLicenseUploader
          heading="Upload Documents"
          subheading="upload as many documents as you can"
          files={license}
          name="documents"
          onChange={onChangeLicenseHandler}
        />
      </section>
    </Wrapper>
  );
};

export default Uploaders;
