import InputIcon from "@mui/icons-material/Input";
import DialpadIcon from "@mui/icons-material/Dialpad";
import MenuIcon from "@mui/icons-material/Menu";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ModalIcon from "@mui/icons-material/ScreenshotMonitor";
import LinearIcon from "@mui/icons-material/LinearScaleSharp";
import TocIcon from "@mui/icons-material/Toc";
import TabIcon from "@mui/icons-material/Tab";
import ExpandIcon from "@mui/icons-material/Expand";
import FormIcon from "@mui/icons-material/Margin";
import ParersIcon from "@mui/icons-material/WrapText";
import PdfIcon from "@mui/icons-material/PictureAsPdfOutlined";
import FileIcon from '@mui/icons-material/ContentCopy';
import { routes } from "../../routes/routes";

export const sidenav = [
  {
    route: routes.BUTTONS,
    title: "Buttons",
    icon: DialpadIcon,
  },
  {
    route: routes.INPUTS,
    title: "Inputs",
    icon: InputIcon,
  },
  {
    route: routes.MENU,
    title: "Menu",
    icon: MenuIcon,
  },
  {
    route: routes.UPLOADERS,
    title: "Uploaders",
    icon: DriveFolderUploadIcon,
  },
  {
    route: routes.MODAL,
    title: "Modals",
    icon: ModalIcon,
  },
  {
    route: routes.STEPPER,
    title: "Stepper",
    icon: LinearIcon,
  },
  {
    route: routes.TABLES,
    title: "Tables",
    icon: TocIcon,
  },
  {
    route: routes.TABS,
    title: "Tabs",
    icon: TabIcon,
  },
  {
    route: routes.EXPAND,
    title: "Expand",
    icon: ExpandIcon,
  },
  {
    route: routes.FORMS,
    title: "Forms",
    icon: FormIcon,
  },
  {
    route: routes.PARSERS,
    title: "Parsers",
    icon: ParersIcon,
  },
  {
    route: routes.CSV,
    title: "CSV",
    icon: FileIcon,
  },
  {
    route: routes.PDF,
    title: "PDF",
    icon: PdfIcon,
  },
];
