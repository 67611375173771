import React from "react";
import styled from "styled-components";

const MenuWrapper = styled.div`
  height: 100%;
  &,
  & > li {
    height: 100%;
  }

  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  .dropdown {
    position: absolute;
    top: 100%;
    background: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
    z-index: 999;
  }

  &:hover .dropdown {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    .chevron-icon {
      transform: rotate(0);
    }
  }
`;

const Menu = ({ button, dropdown }) => {
  return (
    <MenuWrapper className="menu">
      <div>
        {button}
        <div className="dropdown">{dropdown}</div>
      </div>
    </MenuWrapper>
  );
};

export default Menu;
