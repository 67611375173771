import React from "react";
import { LinkButton } from "../Buttons/Regular";
import PrivacyPolicy from "./PrivacyPolicy.pdf";

const DownloadPdf = () => {
  return (
    <LinkButton target="_blank" download to={PrivacyPolicy}>
      Download pdf from file system
    </LinkButton>
  );
};

export default DownloadPdf;
