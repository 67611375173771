import styled from "styled-components";

export const ModalContainer = styled.div`
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  padding: 20px;
`;

export const ModalPosition = styled.div`
  z-index: 999;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.goUp {
    transform: translateY(-100%);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 700;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: transparent;
`;
