import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../components/Modal";
import { Button } from "../../components/Buttons/Regular";
import Camera from "../../components/Camera";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const ContentWrapper = styled.div`
  max-width: 500px;
  overflow: hidden;
  box-sizing: border-box;
`;

const Uploaders = () => {
  const [open, setOpen] = useState(false);
  const [cameraImage, setCameraImage] = useState("");

  const CameraHandler = (image) => {
    if (image) {
      setCameraImage(image);
    }
    setOpen(false);
  };

  return (
    <Wrapper>
      <section>
        <Modal
          open={open}
          handleClose={() => setOpen(false)}
          showHideButton={true}
        >
          <ContentWrapper>
            <Camera handleClose={() => setOpen(false)} cb={CameraHandler} />
          </ContentWrapper>
        </Modal>
        <Button className="contained" onClick={() => setOpen(true)}>
          open modal
        </Button>
      </section>
      <section>
        <img src={cameraImage} alt="" />
      </section>
    </Wrapper>
  );
};

export default Uploaders;
