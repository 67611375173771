import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border: 1px solid #0f8be1;
  border-radius: 2.5rem;
  padding: 3px;

  button {
    padding: 7px 24px;
    border-radius: 25px;
    border: 1px solid transparent;
    background: transparent;
    color: #212529;
    cursor: pointer;
    transition: all 0.1s;
  }

  .active {
    background-color: #0f8be1;
    color: white;
    border: 1px solid #0f8be1;
  }
`;

export default function Toggle({ onChange, labelLeft, labelRight }) {
  const [active, setActive] = useState("left");

  const toggleButton = (e) => {
    const { name } = e.target;
    setActive(name);
    onChange(name);
  };
  return (
    <Wrapper className="toggle-btn">
      <button
        style={{ marginRight: "-4px" }}
        type="button"
        className={active === "left" ? "active" : ""}
        name="left"
        onClick={toggleButton}
      >
        {labelLeft}
      </button>
      <button
        type="button"
        style={{ marginLeft: "-4px" }}
        className={active === "right" ? "active" : ""}
        name="right"
        onClick={toggleButton}
      >
        {labelRight}
      </button>
    </Wrapper>
  );
}
