export const objectToCSV = (data) => {
    // MAKE SURE THERE IS NO NULL IN DATA
    const array = data.filter((item) => item !== null);
    const csvRows = [];
    const headers = Object.keys(array[0]);
    csvRows.push(headers.join(","));
    array.forEach((row) => {
      const values = headers.map((header) => {
        const escape = row[header].toString().replace(/"/g, '\\"');
        return `"${escape}"`;
      });
      csvRows.push(values.join(","));
    });
  
    return csvRows.join("\n");
  };
  
  export const download = (data, fileName) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    const name = fileName || "download";
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${name}.csv`);
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  