import brnv from "bank-routing-number-validator";

// CARD NUMBER
export const validateCardNumber = (value) => {
  return /^(\d{10}|\d{12})$/.test(value);
};

// ACCOUNT NUMBER
const accountNumberRegexp = /^(\d{5,17})$/;

export const validateAccountNumber = (value) => {
  const regexp = new RegExp(accountNumberRegexp);
  return regexp.test(value.trim());
};

// ROUTING NUMBER
export const validateRoutingNumber = (value) => {
  return brnv.ABARoutingNumberIsValid(value);
};

//   CARD EXPIRY
export const creditCardValidateExpiry = (expiryDate) => {
  const date = expiryDate.replace(/^([0-9]{2})([0-9]{2})$/, "$1/$2");
  const ERROR_TEXT_INVALID_EXPIRY_DATE = "Expiry date is invalid";
  const ERROR_TEXT_MONTH_OUT_OF_RANGE =
    "Expiry month should be between 01 and 12";
  const ERROR_TEXT_YEAR_OUT_OF_RANGE = "Your card expired";

  const EXPIRY_DATE_REGEX = /^(\d{2})\/(\d{4}|\d{2})$/;
  const MONTH_REGEX = /(0[1-9]|1[0-2])/;

  const splitDate = date.split("/");
  if (!EXPIRY_DATE_REGEX.test(date)) {
    return ERROR_TEXT_INVALID_EXPIRY_DATE;
  }

  const expiryMonth = splitDate[0];
  if (!MONTH_REGEX.test(expiryMonth)) {
    return ERROR_TEXT_MONTH_OUT_OF_RANGE;
  }

  const expiryYear = splitDate[1];
  let currentYear = new Date().getFullYear();
  currentYear = parseInt(
    expiryYear.length === 4 ? currentYear : currentYear.toString().substr(-2),
    10
  );
  if (currentYear > parseInt(expiryYear, 10)) {
    return ERROR_TEXT_YEAR_OUT_OF_RANGE;
  }

  return "";
};
