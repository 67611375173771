import TextField from "../../components/Controllers/TextField";
import Select from "../../components/Controllers/Select/Default";
import DatePicker from "../../components/Controllers/DatePicker";

const statuses = [
  { id: "1", label: "pending", value: "pending" },
  { id: "2", label: "open", value: "open" },
  { id: "3", label: "declined", value: "declined" },
  { id: "4", label: "approved", value: "approved" },
];
export const formInit = ({ name, email, date, status }) => {
  return {
    name: { value: name || "", message: "", required: true, },
    email: { value: email || "", message: "", required: true },
    date: { value: date || "", message: "", required: true },
    status: { value: status || statuses[0].value, message: "", required: true },
  };
};

export const renderFields = (form) => [
  {
    value: form.name.value,
    message: form.name.message,
    label: "Name",
    name: "name",
    component: TextField,
  },
  {
    value: form.email.value,
    message: form.email.message,
    label: "Email",
    name: "email",
    component: TextField,
  },
  {
    value: form.status.value,
    message: form.status.message,
    label: "Status",
    name: "status",
    component: Select,
    options: statuses,
  },
  {
    value: form.date.value,
    message: form.date.message,
    label: "Date",
    name: "date",
    component: DatePicker,
  },
];
