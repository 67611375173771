export const mockRequest = (data, time = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, time);
  });
};

const items = [
  {
    name: "Temaka",
    email: "test1@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "John",
    email: "test2@test.com",
    status: "approved",
    date: "08/08/2022",
  },
  {
    name: "Jack",
    email: "test3@test.com",
    status: "approved",
    date: "08/08/2022",
  },
  {
    name: "Hannah",
    email: "test4@test.com",
    status: "declined",
    date: "08/08/2022",
  },
  {
    name: "Bob",
    email: "test5@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Christian",
    email: "test6@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Nafees",
    email: "test7@test.com",
    status: "declined",
    date: "08/08/2022",
  },
  {
    name: "Cody",
    email: "test8@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Edward",
    email: "test9@test.com",
    status: "review",
    date: "08/08/2022",
  },
  {
    name: "Animesh",
    email: "test10@test.com",
    status: "review",
    date: "08/08/2022",
  },
  {
    name: "Ytzvan",
    email: "test11@test.com",
    status: "approved",
    date: "08/08/2022",
  },
  {
    name: "Timothy",
    email: "test12@test.com",
    status: "declined",
    date: "08/08/2022",
  },
  {
    name: "Juliano",
    email: "test13@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Jason",
    email: "test14@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Andres",
    email: "test15@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Oscar",
    email: "test16@test.com",
    status: "approved",
    date: "08/08/2022",
  },
  {
    name: "Americo",
    email: "test17@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Anna",
    email: "test18@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Valeria",
    email: "test19@test.com",
    status: "review",
    date: "08/08/2022",
  },
  {
    name: "Dave",
    email: "test20@test.com",
    status: "approved",
    date: "08/08/2022",
  },
  {
    name: "Bracken",
    email: "test21@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Paul",
    email: "test22@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "John",
    email: "test23@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Paul",
    email: "test24@test.com",
    status: "approved",
    date: "08/08/2022",
  },
  {
    name: "Matt",
    email: "test25@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "David",
    email: "test26@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Michael",
    email: "test27@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "John",
    email: "test28@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Bob",
    email: "test29@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Bob",
    email: "test30@test.com",
    status: "pending",
    date: "08/08/2022",
  },
  {
    name: "Michael",
    email: "test31@test.com",
    status: "approved",
    date: "08/08/2022",
  },
  {
    name: "Bob",
    email: "test32@test.com",
    status: "approved",
    date: "08/08/2022",
  },
];

export const tableData = ({ start, end, items, search, status }) => {
  const updatedItems = items
    .filter((item) => (search ? item.name === search : item))
    .filter((item) => (status ? item.status === status : item));
  const total = updatedItems.length;
  const rows = updatedItems.slice(start, end);
  return {
    rows,
    total,
  };
};

export const fetchTable = async ({
  skip,
  perPage,
  search = "",
  status = "",
}) => {
  const start = skip;
  const end = skip + perPage;
  const result = await mockRequest(
    tableData({ start, end, items, search, status })
  );
  const response = {
    data: {
      rows: result.rows,
      total: result.total,
    },
  };
  return response;
};
