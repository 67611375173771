import React from "react";
import styled from "styled-components";
import AccordionHover from "../../components/Menu/HoverMenu";
import ClickMenu from "../../components/Menu/ClickMenu";
import { Button, ButtonContent } from "../../components/Buttons/Regular";
import ClickOutside from "./ClickOutsideMenu";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  & .space-between {
    display: flex;
    justify-content: space-between;
  }
`;

const Content = () => {
  return (
    <ul>
      <li>1234567890123456789</li>
      <li>2</li>
      <li>3</li>
      <li>4</li>
    </ul>
  );
};

const Menu = () => {
  const cb = (isOpen) => {
    console.log(isOpen ? "open" : "closed");
  };
  return (
    <Wrapper>
      <section>
        <AccordionHover
          button={<Button className="contained">Hover Menu</Button>}
          dropdown={<Content />}
        />
      </section>
      <section>
        <ClickMenu
          button={<ButtonContent className="contained">Open</ButtonContent>}
          menu={Content}
          cb={cb}
        />
      </section>
      <section className="space-between">
        <ClickOutside />
        <ClickOutside />
      </section>
    </Wrapper>
  );
};

export default Menu;
