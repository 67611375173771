export const download = (data, fileName) => {
  const blob = new Blob([data], { type: "text/pdf" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  const name = fileName || "download";
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", `${name}.pdf`);
  document.body.appendChild(a);
  a.click();
  a.remove();
};
