import React, { useState, useRef } from "react";
import styled from "styled-components";
import DropDown from "../../components/Menu/ClickOutsideMenu";
import { Button } from "../../components/Buttons/Regular";
import MenuIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";

const MenuWrapper = styled.ul`
  padding: 20px;
  background: #fff;
  z-index: 100;
  list-style: none;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 230px;
  margin: 0;

  & li {
    display: flex;
    align-items: center;
    gap: 12px;

    & button {
      font-weight: 700;
      text-transform: upperCase;
      font-size: 10px;
      border: none;
      background: transparent;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }

    .icon-wrapper {
      height: 15px;
      width: 15px;
      display: flex;
      justify-content: center;
    }

    & svg {
      height: 100%;
      & path {
        fill: var(--color-primary-dark-1);
      }
    }
  }
`;

const menuList = [
  { icon: MenuIcon, label: "View application" },
  { icon: MenuIcon, label: "Certify Application" },
  { icon: MenuIcon, label: "Application History" },
  { icon: MenuIcon, label: "Withdraw Application" },
  { icon: MenuIcon, label: "Continue Pre-Approval" },
  { icon: MenuIcon, label: "Release to Servicing date" },
  { icon: MenuIcon, label: "First Payment Due Date" },
  { icon: MenuIcon, label: "Status" },
];

const Menu = () => {
  return (
    <MenuWrapper>
      {menuList.map(({ icon: Icon, label }) => {
        return (
          <li key={label}>
            <div className="icon-wrapper">
              <Icon />
            </div>
            <button type="button">{label}</button>
          </li>
        );
      })}
    </MenuWrapper>
  );
};

const TriggerButton = () => {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Button type="button" ref={buttonRef} onClick={() => setOpen(true)}>
        Menu
      </Button>
      <DropDown
        open={open}
        onClose={() => setOpen(false)}
        buttonRef={buttonRef?.current}
      >
        <Menu />
      </DropDown>
    </div>
  );
};

export default TriggerButton;
