import React from "react";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/EditOutlined";
import ChevronLeft from "@mui/icons-material/ChevronLeftOutlined";
import SaveIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Clear";
import LogoutIcon from "@mui/icons-material/Logout";
import BellIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../Loaders/Loader";
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

const Button = styled.button`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #b7b7b7;
  background: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .preloader {
    margin-top: 3px;
  }
`;

const ActionButton = ({
  onClick,
  type,
  loading,
  color,
  fontSize = "24px",
  buttonRef,
}) => {
  const config = {
    color,
    fontSize,
  };
  const renderIcon = () => {
    if (type === "edit") {
      return <EditIcon sx={config} />;
    }
    if (type === "goback") {
      return <ChevronLeft sx={config} />;
    }
    if (type === "save") {
      return loading ? (
        <Loader size="4" position="center" />
      ) : (
        <SaveIcon sx={config} />
      );
    }

    if (type === "cancel") {
      return <CancelIcon sx={config} />;
    }
    if (type === "logout") {
      return <LogoutIcon sx={config} />;
    }
    if (type === "notifications") {
      return <BellIcon sx={config} />;
    }
    if (type === "settings") {
      return <SettingsIcon sx={config} />;
    }
    if (type === "remove") {
      return <RemoveIcon sx={config} />;
    }
    if (type === "menu") {
      return <MenuIcon sx={config} />;
    }
    if (type === "delete") {
      return loading ? (
        <Loader size="4" position="center" />
      ) : (
        <DeleteIcon sx={config} />
      );
    }
    if (type === "expand") {
      return <ExpandMoreIcon sx={config} />;
    }
    if (type === "fold") {
      return <ExpandLessIcon sx={config} />;
    }
    return <></>;
  };
  return (
    <Button
      type="button"
      onClick={onClick}
      className="action-button"
      ref={buttonRef}
    >
      {renderIcon()}
    </Button>
  );
};

export default ActionButton;
