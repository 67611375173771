import React from "react";
import styled from "styled-components";
import BurgerMenu from "../../components/Buttons/BurgerMenu";
import { Button, LinkButton } from "../../components/Buttons/Regular";
import Toggle from "../../components/Buttons/Toggle/Type-1";
import ActionBtn from "../../components/Buttons/ActionButtons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const Buttons = () => {
  return (
    <Wrapper>
      <section>
        <BurgerMenu />
      </section>
      <section>
        <Button className="contained">Contained</Button>
        <Button className="contained round-border">
          Contained round border
        </Button>
        <Button className="outlined">Outlined</Button>
        <Button className="grey">Grey</Button>
        <Button className="lightgrey">Light Grey</Button>
        <Button className="contained" disabled>
          Contained disabled
        </Button>
        <LinkButton className="contained" to="/inputs">
          Link button
        </LinkButton>
      </section>
      <section>
        <Toggle labelLeft="Button left" labelRight="Button right" />
      </section>
      <section>
        <ActionBtn type="edit" />
        <ActionBtn type="goback" />
        <ActionBtn type="save" />
        <ActionBtn type="cancel" />
        <ActionBtn type="logout" />
        <ActionBtn type="notifications" />
        <ActionBtn type="settings" />
        <ActionBtn type="menu" />
        <ActionBtn type="delete" />
      </section>
    </Wrapper>
  );
};

export default Buttons;
