import React from "react";
import styled from "styled-components";
import Select from "../../Controllers/Select/Default";
import DatePicker from "../../Controllers/DatePicker";
import FormattedField from "../../Controllers/FormattedField";
import { colOptions, equationOptions } from "./config";
import Button from "../../Buttons/ActionButtons";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 320px)) 40px;
  gap: 40px;
  align-items: center;
`;

const isDateCol = (value) => ["loanDate", "repaymentDate"].includes(value);

const Form = ({
  colName,
  equation,
  value,
  onChange,
  onRemoveHandler,
  showRemoveBtn,
}) => {
  return (
    <Wrapper>
      <Select
        value={colName}
        name="colName"
        options={colOptions}
        onChange={onChange}
      />
      <Select
        value={equation}
        name="equation"
        options={equationOptions}
        onChange={onChange}
      />
      {isDateCol(colName) ? (
        <DatePicker value={value} name="value" onChange={onChange} />
      ) : (
        <FormattedField
          value={value}
          name="value"
          onChange={onChange}
          thousandSeparator
          prefix="$"
        />
      )}
      {showRemoveBtn && <Button type="remove" onClick={onRemoveHandler} />}
    </Wrapper>
  );
};

export default Form;
