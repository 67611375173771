import React from "react";
import styled from "styled-components";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const Wrapper = styled.div`
  label {
    cursor: pointer;
  }
`;

const FileUploader = ({ name = "name", onChange, accept = "file/*" }) => {
  return (
    <Wrapper>
      <label htmlFor={name}>
        <input
          accept={accept}
          hidden
          id={name}
          type="file"
          name={name}
          multiple
          onChange={onChange}
        />
        <span>
          <FileUploadOutlinedIcon sx={{ fontSize: 24, color: "#58595B" }} />
        </span>
      </label>
    </Wrapper>
  );
};

export default FileUploader;
