import React from "react";
import styled from "styled-components";
import ImageIcon from "@mui/icons-material/ImageOutlined";

const Field = styled.li`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  &:not(:first-child) {
    margin-top: 12px;
  }

  .file {
    &-info-wrapper {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    &-name {
      color: #1e84be;
      margin-bottom: 2px;
    }
  }
  .buttons-wrapper {
    display: flex;
    justify-content: center;
    width: 25px;
  }

  .placeholder {
    font-size: 12px;
    color: #b7b7b7;
  }
  .note {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-weight: normal;
    color: #222222;
    margin: 0;
  }
`;

const FileField = ({ label, button }) => {
  return (
    <Field>
      <div className="file-info-wrapper">
        <ImageIcon />
        <div className="file-name-box">
          <p className="file-name note">{label}</p>
        </div>
      </div>
      <div className="buttons-wrapper">{button}</div>
    </Field>
  );
};

export default FileField;
