import React, { useState } from "react";
import styled from "styled-components";
import AccordionMui from "../../components/Expand/Accordion-Mui";
import { Button } from "../../components/Buttons/Regular";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const items = [
  {
    value: [
      { id: "1", label: "placeholder for this line" },
      { id: "2", label: "placeholder for this line" },
      { id: "3", label: "placeholder for this line" },
      { id: "4", label: "placeholder for this line" },
      { id: "5", label: "placeholder for this line" },
    ],
    label: "list 1",
    id: "1",
  },
  {
    value: [
      { id: "1", label: "placeholder for this line" },
      { id: "2", label: "placeholder for this line" },
      { id: "3", label: "placeholder for this line" },
      { id: "4", label: "placeholder for this line" },
      { id: "5", label: "placeholder for this line" },
    ],
    label: "list 2",
    id: "2",
  },
  {
    value: [
      { id: "1", label: "placeholder for this line" },
      { id: "2", label: "placeholder for this line" },
      { id: "3", label: "placeholder for this line" },
      { id: "4", label: "placeholder for this line" },
      { id: "5", label: "placeholder for this line" },
    ],
    label: "list 3",
    id: "3",
  },
];

const Content = ({ list = [] }) => {
  return (
    <ul>
      {list.map((item) => {
        return <li key={item.id}>{item.label}</li>;
      })}
    </ul>
  );
};

const Menu = () => {
  const [expanded, setExpanded] = useState("");
  return (
    <Wrapper>
      <section>
        <AccordionWrapper>
          {items.map((item) => {
            return (
              <div key={item.id}>
                <AccordionMui
                  content={<Content list={item.value} />}
                  expanded={expanded === item.id}
                />
                <Button
                  className="outlined"
                  onClick={() => setExpanded(item.id)}
                >
                  {item.label}
                </Button>
              </div>
            );
          })}
        </AccordionWrapper>
      </section>
    </Wrapper>
  );
};

export default Menu;
