import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Buttons/Regular";
import { objectToCSV, download } from "./utils";
import { table } from "./mock";

const Wrapper = styled.div`
  text-align: right;
  padding: 20px;

  .button-error {
    color: red;
    margin: 5px 0;
    font-size: 12px;
  }
`;

const GenerateReport = ({ data, fileName }) => {
  const [error, setError] = useState("");
  const generateHandler = () => {
    if (error) setError("");
    if (data && Array.isArray(data)) {
      const object = objectToCSV(data);
      if (object) {
        download(object, fileName);
      }
    } else {
      setError("no convertible data can be found");
    }
  };
  return (
    <Wrapper>
      <Button variant="primary" size="medium" onClick={generateHandler}>
        Generate CSV Report
      </Button>
      {error ? <div className="button-error">{error}</div> : ""}
    </Wrapper>
  );
};

const Report = () => <GenerateReport data={table} fileName="CSV Report" />;

export default Report;
