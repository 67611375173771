import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #ebebeb;
  padding: 24px;
  &.active {
    border: 1px solid #b7b7b7;
  }

  .upload-button-wrapper {
    width: 25px;
    display: flex;
    justify-content: center;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  h3 {
    margin: 0;
  }
  .upload-field {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    & label {
      cursor: pointer;
    }

    &-title {
      & > .note {
        margin: 12px 0;
        color: #58595b;
      }

      & > .message {
        color: red;
      }
    }
  }
`;

export const Note = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-weight: normal;
  color: #222222;
`;