import { css } from "styled-components";
import { input } from "./input";

export const select = css`
  ${input}

  select {
    appearance: none;
    padding-right: 40px;
    min-width: 120px;
  }
  .select-wrapper {
    background: #fff;
    border: transparent;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 24px;
      width: 24px;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url("${process.env.PUBLIC_URL}/images/chevron.svg") no-repeat;
      pointer-events: none;
      z-index: 10;
    }
  }
`;
