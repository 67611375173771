import React from "react";
import Error from "../Elements/FieldError";
import { InputWrapper } from "../styles";
import Label from "../Elements/FieldLabel";

const InputField = ({
  value = "",
  label = "",
  valid,
  type = "text",
  disabled = false,
  message = "",
  onChange,
  name = "field",
  placeholder = "",
}) => {
  const error = !!message;
  return (
    <InputWrapper
      className={`textField ${error ? "error" : ""} ${value ? "isFilled" : ""}`}
      valid={!error && valid}
    >
      {label ? <Label label={label} /> : ""}
      <div style={{ position: "relative" }}>
        <input
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          name={name}
          onChange={onChange}
        />
      </div>
      <Error message={message} />
    </InputWrapper>
  );
};

export default InputField;
