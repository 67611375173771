import React from "react";
import styled from "styled-components";
import { useStepper } from "./context/stepper";
import stepCompletedImg from "./assets/completed.svg";
import stepActiveImg from "./assets/active.svg";
import stepIncompleteImg from "./assets/incomplete.svg";

const Styles = styled.div`
  min-height: 80px;
  padding: 0 0 12px 30px;
  position: relative;

  .step-number {
    position: absolute;
    border-radius: 50%;
    padding: 6px;
    top: 6px;
    left: 0;
    transform: translate(-50%, -20%);
    background: #fff;
  }

  .step-number-inner,
  .step-edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    line-height: 12px;
    font-weight: 700;
  }

  .step-number-inner {
    border-radius: 50%;
  }

  .step-edit-btn {
    background: #fff;
    color: #1e84be;
    transform: translate(0, 0);
    border: none;
    font-size: 12px;
  }

  & > .heading-wrapper {
    display: flex;
    align-items: center;
    & .heading {
      font-weight: 700;
      position: relative;
      font-size: 12px;
      line-height: 30px;
      display: flex;
      align-items: center;
      column-gap: 12px;
    }
  }

  .content-wrapper {
    padding: 0;
    opacity: 0;
    visibility: hidden;
  }

  .active .content-wrapper {
    opacity: 1;
    visibility: visible;
  }

  .completed img {
    & path {
      fill: #fff;
    }
  }

  @media screen and (max-width: 767px) {
    & {
      padding: 0 0 1.2rem 1.6rem;
    }
  }
`;

const Step = ({ number, name, completed, active, children }) => {
  const { goToStep } = useStepper();

  const renderStepIcon = () => {
    switch (true) {
      case active:
        return stepActiveImg;
      case completed:
        return stepCompletedImg;
      default:
        return stepIncompleteImg;
    }
  };

  return (
    <Styles className="step">
      <div className="step-number" id={number}>
        <img src={renderStepIcon()} alt={number} />
      </div>
      <div className="heading-wrapper">
        <div
          className="heading"
          style={{
            color: active ? "var(--color-blue-1)" : "#b7b7b7",
          }}
        >
          {name}
          {completed && !active ? (
            <button
              type="button"
              className="step-edit-btn"
              onClick={() => goToStep(number)}
            >
              Edit
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={`${active ? "active" : ""}`}>
        <div className="content-wrapper">{children}</div>
      </div>
    </Styles>
  );
};

export default Step;
