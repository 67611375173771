export const filters = [
    {
      label: "pending",
      active: true,
      value: "pending",
    },
    {
      label: "approved",
      active: false,
      value: "approved",
    },
    {
      label: "declined",
      active: false,
      value: "declined",
    },
    {
      label: "review",
      active: false,
      value: "review",
    },
  ];