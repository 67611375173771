import React, { useState } from "react";
import styled from "styled-components";
import { formInit, renderFields } from "./config";
import { Button } from "../../components/Buttons/Regular";

const Wrapper = styled.div`
  padding: 20px;
  .fields-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`;

const Form = ({ state, handleClose, cb }) => {
  const [form, setForm] = useState(formInit(state));

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], value, message: "" },
    }));
  };

  const onSubmit = () => {
    handleClose();
    cb();
  };

  return (
    <Wrapper>
      <div className="fields-wrapper">
        {renderFields(form).map(({ component: Component, ...field }) => {
          return (
            <Component key={field.name} onChange={onChangeHandler} {...field} />
          );
        })}
        <Button onClick={handleClose} className="outlined">
          Cancel
        </Button>
        <Button onClick={onSubmit} className="contained">
          Confirm
        </Button>
      </div>
    </Wrapper>
  );
};

export default Form;
