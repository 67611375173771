import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Stepper from "../../components/Stepper";
import SimpleStepper from "./HorizontalSteppers/SimpleProgress";
import { steps } from "./VerticalStepper/steps";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const StepperPage = () => {
  const [initStep, setInitStep] = useState(1);

  useEffect(() => {
    setInitStep(1);
  }, []);

  return (
    <Wrapper>
      <section>
        <Stepper steps={steps} initStep={initStep} />
      </section>
      <section>
        <SimpleStepper />
      </section>
    </Wrapper>
  );
};

export default StepperPage;
