import React from "react";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// fix styles that conflict with another mui components
const TabsWrapper = styled.div`
  & .MuiTabs-root .MuiButtonBase-root.MuiTab-root {
    min-height: 48px;
    text-transform: capitalize;
  }
`;

const BasicTabs = ({ buttons, onChange, activeTab, scrollable }) => {
  const active = buttons.findIndex((item) => item.value === activeTab);

  const handleChange = (event, newValue) => {
    onChange(buttons[newValue].value);
  };

  return (
    <TabsWrapper>
      <Tabs value={active} onChange={handleChange} aria-label="basic tabs example" variant={scrollable ? "scrollable" : "standard"}>
        {buttons.map((tab) => (
          <Tab label={tab.label} key={tab.value} />
        ))}
      </Tabs>
    </TabsWrapper>
  );
};

export default BasicTabs;
