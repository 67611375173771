import React, { useState } from "react";
import styled from "styled-components";
import CheckBox from "../../components/Controllers/CheckBox/Custom";
import DefaultCheckbox from "../../components/Controllers/CheckBox/Default";
import TextField from "../../components/Controllers/TextField/Placeholder-label";
import TextFieldRegular from "../../components/Controllers/TextField";
import RegularFormattedField from "../../components/Controllers/FormattedField";
import RoutingNumber from "../../components/Controllers/RoutingNumber";
import FormattedField from "../../components/Controllers/FormattedField/Placeholder-label";
import Password from "../../components/Controllers/Password/Placeholder-label";
import PasswordRegular from "../../components/Controllers/Password";
import Select from "../../components/Controllers/Select/Default/Placeholder-label";
import SelectRegular from "../../components/Controllers/Select/Default";
import TextArea from "../../components/Controllers/TextArea";
import RadioGroup from "../../components/Controllers/RadioButton";
import SearchDebounce from "../../components/Controllers/Search/Debounce";
import SearchUrlQuery from "../../components/Controllers/Search/UrlQuery";
import DatePicker from "../../components/Controllers/DatePicker";
import DatePickerMui from "../../components/Controllers/DatePicker/Placeholder-label";
import Toggle from "../../components/Controllers/Toggle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const options = [
  { id: "0", label: "", value: "" },
  { id: "1", label: "option 1", value: "option 1" },
  { id: "2", label: "option 2", value: "option 2" },
  { id: "3", label: "option 3", value: "option 3" },
  { id: "4", label: "option 4", value: "option 4" },
];

const Inputs = () => {
  const [query, setQuery] = useState("");
  const [form, setForm] = useState({
    password: { value: "", message: "" },
    date: { value: null, message: "" },
    select: { value: options[0].value, message: "" },
    text: { value: "", message: "" },
    amount: { value: "", message: "" },
    phone: { value: "", message: "" },
    checkbox: { value: false, message: "" },
  });

  const onSearchHandler = (value) => {
    setQuery(value);
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: { value, message: "" } }));
  };

  return (
    <Wrapper>
      <section>
        <CheckBox
          value={form.checkbox.value}
          name="checkbox"
          label="Checkbox label"
          onChange={onChangeHandler}
        />
        <DefaultCheckbox
          value={form.checkbox.value}
          name="checkbox"
          label="Checkbox label"
          onChange={onChangeHandler}
        />
      </section>
      <section>
        <Toggle
          value={form.checkbox.value}
          name="checkbox"
          onChange={onChangeHandler}
        />
      </section>
      <section>
        <RadioGroup
          options={options}
          onChange={onChangeHandler}
          currentSelection={form.select.value}
          name="select"
        />
      </section>
      <section>
        <RoutingNumber
          onChange={onChangeHandler}
          value={form.amount.value}
          label="Text field"
          name="amount"
        />
        <RegularFormattedField
          onChange={onChangeHandler}
          value={form.amount.value}
          name="amount"
          placeholder="enter amount"
          label="Amount"
          prefix="$"
          thousandSeparator
        />
        <PasswordRegular
          onChange={onChangeHandler}
          value={form.password.value}
          label="Password"
          name="password"
          placeholder="enter password"
        />
        <SelectRegular
          onChange={onChangeHandler}
          value={form.select.value}
          label="Select"
          options={options}
          name="select"
        />
        <TextFieldRegular
          onChange={onChangeHandler}
          value={form.text.value}
          name="text"
          label="Text field"
        />
        <DatePicker
          onChange={onChangeHandler}
          value={form.date.value}
          label="Date picker"
          name="date"
        />
        <TextArea
          onChange={onChangeHandler}
          value={form.text.value}
          label="Text area"
          name="text"
        />
      </section>
      <section>
        <TextField
          onChange={onChangeHandler}
          value={form.text.value}
          label="Text field"
          name="text"
        />
        <FormattedField
          onChange={onChangeHandler}
          value={form.amount.value}
          placeholder="enter amount"
          name="amount"
          label="Amount"
          prefix="$"
          thousandSeparator
        />
        <Select
          onChange={onChangeHandler}
          value={form.select.value}
          label="Select"
          name="select"
          options={options}
        />
        <DatePickerMui
          onChange={onChangeHandler}
          value={form.date.value}
          label="Date picker"
          name="date"
        />
        <FormattedField
          onChange={onChangeHandler}
          value={form.phone.value}
          placeholder="enter phone number"
          label="Phone number"
          mask="_"
          format="+1 (###) ### ####"
          name="phone"
        />
        <FormattedField
          onChange={onChangeHandler}
          value={form.amount.value}
          label="Expiration Date"
          placeholder="Expiration (MM/YY)"
          format="##/##"
          mask={["M", "M", "Y", "Y"]}
          name="amount"
        />
        <FormattedField
          onChange={onChangeHandler}
          value={form.amount.value}
          label="Card Number"
          placeholder="Card Number"
          format="#### #### #### ####"
          name="amount"
        />
        <Password
          onChange={onChangeHandler}
          value={form.password.value}
          label="Password"
          placeholder="enter password"
          name="password"
        />
      </section>
      <section>
        <SearchDebounce searchHandler={onSearchHandler} />
        <div>{query}</div>
        <SearchUrlQuery route="/inputs" />
      </section>
    </Wrapper>
  );
};

export default Inputs;
