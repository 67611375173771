export const routes = {
    BUTTONS: "/buttons",
    INPUTS: "/inputs",
    MENU: "/menu",
    UPLOADERS: "/uploaders",
    MODAL: "/modal",
    STEPPER: "/stepper",
    TABLES: "/tables",
    TABS: "/tabs",
    EXPAND: "/expand",
    FORMS: "/forms",
    PARSERS: "/parsers",
    PDF: "/pdf",
    CSV: "/csv",
}