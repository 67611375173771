import { css } from "styled-components";
import { isValid, isInvalid, error } from "./actives";

export const input = css`
  background: transparent;
  display: flex;
  flex-direction: column;
  position: relative;

  input,
  select,
  textarea {
    width: 100%;
    padding: 20px 16px;
    font-size: 14px;
    outline: none;
    background-color: #fff;
    border: 1px solid #ebebeb;
    color: #5a5a5e;
    box-sizing: border-box;
    position: relative;
    font-weight: 400;
    z-index: 2;
    transition: all 0.2s;
    &::placeholder {
      color: #b7b7b7;
    }

    &:hover {
      background: #f9f9f9;
      border-color: #ebebeb;
    }
    &:focus {
      border-color: #4da696;
      background: #fff;

      & + .field-label {
        color: #4da696;
      }
    }
  }

  ${isValid}
  ${isInvalid}
  ${error}
`;
