import {
  creditCardValidateExpiry,
  validateCardNumber,
  validateAccountNumber,
} from "../../../validators/bank";
import { validatePassword } from "../../../validators/password";
import { validateDob } from "../../../validators/dob";
import { validateSSN } from "../../../validators/ssn";
import { validateEmail } from "../../../validators/email";
import { validatePhone } from "../../../validators/phone";
import { validateZipCode } from "../../../validators/zip";
import { validateUrl } from "../../../validators/url";

export const validate = (form) => {
  const newForm = { ...form };
  let isValid = true;
  Object.keys(newForm).forEach((key) => {
    if (key === "expirationDate") {
      const message = creditCardValidateExpiry(newForm[key].value);
      if (message) {
        newForm[key].message = message;
        isValid = false;
      }
    } else if (key === "securityCode") {
      if (newForm[key].value.length < 3) {
        newForm[key].message = "enter a 3 digit code";
        isValid = false;
      }
    } else if (key === "ssn") {
      const isSSNValid = validateSSN(newForm[key].value);
      if (!isSSNValid) {
        newForm[key].message = "Enter a valid number";
        isValid = false;
      }
    } else if (key === "email") {
      const isEmailValid = validateEmail(newForm[key].value);
      if (!isEmailValid) {
        newForm[key].message = "Enter a valid email";
        isValid = false;
      }
    } else if (key === "website") {
      const isUrlValid = validateUrl(newForm[key].value);
      if (!isUrlValid) {
        newForm[key].message = "enter a valid address";
        isValid = false;
      }
    } else if (key === "cardNumber") {
      const isCardNumberValid = validateCardNumber(newForm[key].value);
      if (!isCardNumberValid) {
        newForm[key].message = "enter a valid number";
        isValid = false;
      }
    } else if (key === "bankAccount") {
      const isAccountNumberValid = validateAccountNumber(newForm[key].value);
      if (!isAccountNumberValid) {
        newForm[key].message = "enter a valid number";
        isValid = false;
      }
    } else if (key === "phoneNumber") {
      const isPhoneNumberValid = validatePhone(newForm[key].value);
      if (!isPhoneNumberValid) {
        newForm[key].message = "enter a valid number";
        isValid = false;
      }
    } else if (key === "zipCode") {
      const isZipCodeValid = validateZipCode(newForm[key].value);
      if (!isZipCodeValid) {
        newForm[key].message = "enter a valid number";
        isValid = false;
      }
    } else if (key === "dob") {
      const message = validateDob(newForm[key].value);
      if (message) {
        isValid = false;
        newForm[key].message = message;
      }
    } else if (key === "password" || key === "rePassword") {
      const [isPasswordValid, passwordMessage, rePasswordMessage] =
        validatePassword({
          password: newForm.password.value,
        });
      if (!isPasswordValid) {
        isValid = false;
        if (passwordMessage) newForm.password.message = passwordMessage;
        else if (rePasswordMessage)
          newForm.rePassword.message = rePasswordMessage;
        else newForm.password.message = "enter a valid password";
      }
    }
  });
  return [isValid, newForm];
};
