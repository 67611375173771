import React, { useState } from "react";
import styled from "styled-components";
import CustomTabs from "../../components/Tabs/Custom";
import MaterialTabs from "../../components/Tabs/Material-ui";
import Content, { config } from "./Content";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const TabsPage = () => {
  const [activeTab, setActiveTab] = useState(config[0].value);

  const onChangeTabHandler = (value) => {
    setActiveTab(value);
  };

  return (
    <Wrapper>
      <section>
        <CustomTabs
          activeTab={activeTab}
          onChange={onChangeTabHandler}
          buttons={config}
        />
      </section>
      <section>
        <MaterialTabs
          activeTab={activeTab}
          onChange={onChangeTabHandler}
          buttons={config}
        />
      </section>
      <section>
        <Content activeTab={activeTab} />
      </section>
    </Wrapper>
  );
};

export default TabsPage;
