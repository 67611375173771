import React from "react";
import CurrencyFormat from "react-number-format";

export const formatCurrency = (value, decimalScale = 0) =>
  value !== undefined ? (
    <CurrencyFormat
      value={value}
      displayType="text"
      thousandSeparator
      decimalScale={decimalScale}
      fixedDecimalScale
      prefix="$"
    />
  ) : (
    <>--</>
  );
