import { v4 as uuid } from "uuid";

export function getBase64(file) {
  return new Promise((resolve) => {
    let baseURL;
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader?.result
        ?.toString()
        .replace("data:", "")
        .replace(/^.+,/, "");
      resolve(baseURL);
    };
  });
}

// READ A FILE VALUE FROM INPUT FIELD
export const add = (e) => {
  const newDocuments = [];
  const newFiles = Array.from(e.target.files);
  newFiles.forEach((file, index) => {
    newDocuments[index] = file;
    newDocuments[index].id = uuid();
  });
  e.target.value = "";
  return newDocuments;
};

// REMOVE A FILE FROM FORM
export const remove = ({ id, files }) => {
  if (files && files?.length) {
    const updatedList = files?.filter((item) => item.id !== id);
    return updatedList;
  }

  return files;
};

// SET A LIMIT OF FILES THAT CAN BE UPLOADED PER INPUT
export const applyLimit = ({ array = [], limit = 0 }) => {
  if (limit > 1) {
    return array.slice(0, limit);
  }
  return array;
};

// PARSE AN INPUT FILE TO NEW OBJECT WITH FILE VALUE CONVERTED TO BASE64 STRING
export const parseFilesToStrings = async (files) => {
  return Promise.all(
    files.map(async (file) => {
      const base64String = await getBase64(file);
      return { label: file.name, value: base64String, id: file.id };
    })
  );
};

// PARSE FORM OF FILES TO REQUEST BODY
// {insurance: [ {label: string, value: string, id: string} ]}
export const parseDocumentsFormToRequestBody = async (files) => {
  const docs = {};
  await Promise.all(
    Object.keys(files).map(async (key) => {
      const res = await parseFilesToStrings(files[key].value);
      docs[key] = res;
    })
  );

  return docs;
};
