import React, { useState } from "react";
import styled from "styled-components";
import {
  formInit,
  personalFields,
  addressFields,
  billingFields,
  passwordField,
} from "./config";
import { Button } from "../../Buttons/Regular";
import { H5 as Header } from "../../Typography";
import { validate } from "./validation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .heading {
    margin: 0;
  }
  padding: 20px;
  .fields-wrapper {
    max-width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`;

const Form = ({ state, handleClose, cb }) => {
  const [form, setForm] = useState(formInit(state));

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], value, message: "" },
    }));
  };

  const onSubmit = () => {
    const [isValid, updatedForm] = validate(form);
    if (!isValid) {
      setForm(updatedForm);
    }
  };
 
  return (
    <Wrapper>
      <Header className="heading">Personal Info</Header>
      <div className="fields-wrapper">
        {personalFields(form).map(({ component: Component, ...field }) => {
          return (
            <Component key={field.name} onChange={onChangeHandler} {...field} />
          );
        })}
      </div>
      <Header className="heading">Address</Header>
      <div className="fields-wrapper">
        {addressFields(form).map(({ component: Component, ...field }) => {
          return (
            <Component key={field.name} onChange={onChangeHandler} {...field} />
          );
        })}
      </div>
      <Header className="heading">Billing Info</Header>
      <div className="fields-wrapper">
        {billingFields(form).map(({ component: Component, ...field }) => {
          return (
            <Component key={field.name} onChange={onChangeHandler} {...field} />
          );
        })}
      </div>
      <Header className="heading">Password</Header>
      <div className="fields-wrapper">
        {passwordField(form).map(({ component: Component, ...field }) => {
          return (
            <Component key={field.name} onChange={onChangeHandler} {...field} />
          );
        })}
      </div>
      <div className="fields-wrapper">
        <Button onClick={handleClose} className="outlined">
          Cancel
        </Button>
        <Button onClick={onSubmit} className="contained">
          Validate
        </Button>
      </div>
    </Wrapper>
  );
};

export default Form;
