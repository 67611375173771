import React from "react";
import styled from "styled-components";
import DownloadPdfLocally from "../../components/PDF/LocalFile-download";
import DownloadByUrl from "../../components/PDF/RemoteFile-download";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

const Csv = () => {
  return (
    <Wrapper>
      <section>
        <DownloadPdfLocally />
      </section>
      <section>
        <DownloadByUrl />
      </section>
    </Wrapper>
  );
};

export default Csv;
