import React from "react";
import CurrencyFormat from "react-number-format";

export const formatSSN = (number) => {
  if (typeof number === "string") {
    return (
      <CurrencyFormat value={number} displayType="text" format="### ### ####" />
    );
  }
  return number;
};
