import React from "react";
import TextField from "@material-ui/core/TextField";

export default function DatePickers({ label, value, name, onChange }) {
  return (
    <TextField
      id="date"
      label={label}
      type="date"
      name={name}
      onChange={onChange}
      defaultValue={value}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}
