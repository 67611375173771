import React, { useState } from "react";
import styled from "styled-components";
import Progress from "../../../../components/Progress/Simple";
import { Button } from "../../../../components/Buttons/Regular";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
  .progressbar {
  }
`;

const Component = () => {
  const [step, setStep] = useState(1);
  const totalSteps = 7;

  const stepHandler = () => {
    if (step === totalSteps) {
      setStep(1);
    } else {
      setStep(step + 1);
    }
  };
  return (
    <Wrapper>
      <Progress numberOfSteps={totalSteps} currentStepNumber={step} />
      <div>
        <Button onClick={stepHandler} className="outlined">
          Next
        </Button>
      </div>
    </Wrapper>
  );
};

export default Component;
