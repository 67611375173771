import { v4 as uuid } from "uuid";

export const onChangeFormHandler = ({ e, form, rowId }) => {
  const { value, name } = e.target;
  const updatedForm = [...form];
  const rowIndex = updatedForm.findIndex((r) => r.id === rowId);
  updatedForm[rowIndex][name] = value;
  return updatedForm;
};

export const onToggle = ({ value, rowId, form }) => {
  const updatedValue = value === "1" ? "and" : "or";
  const updatedForm = [...form];
  const rowIndex = updatedForm.findIndex((r) => r.id === rowId);
  updatedForm[rowIndex].condition = updatedValue;
  return updatedForm;
};

export const onAddCondition = (form) => {
  const rowId = uuid();
  const condition = {
    condition: "and",
    type: "condition",
    id: uuid(),
    rowId,
  };
  const row = {
    colName: "",
    equation: "",
    value: "",
    type: "fields",
    id: rowId,
  };

  const updatedForm = [...form];
  if (updatedForm.length > 0) {
    updatedForm.push(condition);
  }
  updatedForm.push(row);
  return updatedForm;
};
