import React from "react";
import { useHistory } from "react-router-dom";
import SideNav from "./sidenav/SideNav";
import Wrapper from "./styles";
import { sidenav } from "./sidenav/sidenav.config";

const MainLayout = ({ children }) => {
  const history = useHistory();
  const active = history?.location?.pathname;
  return (
    <Wrapper className="layout">
      <SideNav active={active} navItems={sidenav} />
      <div className="main-wrapper">
        <main className="main">{children}</main>
      </div>
    </Wrapper>
  );
};
export default MainLayout;
