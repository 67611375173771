import { css } from "styled-components";

export const isValid = css`
  &.isValid {
    & input,
    & textarea {
      border-color: transparent;
      border-radius: 6px;
      box-shadow: 0 0 0 2px #06c270;

      &:focus {
        border-color: transparent;
        box-shadow: 0 0 0 2px #06c270;
      }
    }
  }
`;

export const isInvalid = css`
  &.isInvalid {
    & input,
    & textarea {
      &:focus {
        box-shadow: red;
      }
    }
  }
`;

export const error = css`
  &.error {
    margin: 5px 0 0 5px;

    & .field-label {
      color: #b63a4d;
    }
    & input,
    & select,
    & textarea {
      &,
      &:hover,
      &:active,
      &:focus {
        border-color: #b63a4d;
      }
    }
  }
`;

export const isFilled = css`
  &.isFilled {
    & input,
    & select,
    & textarea {
      border-color: #be881e;
      background: #fefcf8;
    }
  }
`;