import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const button = css`
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.4s;
  padding: 12px;
  border: 1px solid transparent;
  min-width: 120px;

  &.round-border {
    border-radius: 8px;
  }

  &.contained {
    background: #0f8be1;
    color: #fff;

    & svg path {
      stroke: #fff;
    }

    &:hover {
      background: #0f8be1;
      color: #fff;
    }
  }

  &.outlined {
    font-weight: 600;
    background: transparent;
    color: #5a7da1;
    border: 1px solid #cbdae9;

    & svg path {
      stroke: #5a7da1;
    }
  }

  &.grey {
    background: #d4e1e6;
    color: #5a7da1;

    & svg path {
      stroke: #5a7da1;
    }

    &:hover {
      background: #d4e1e6;
      color: #1a3855;
      border: 1px solid #d4e1e6;
    }
  }

  &.lightgrey {
    background: #efefef;
    color: #5a7da1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 3px 3px 5px 0 rgb(0 47 94 / 11%);
    transition: all 0.1s;
  }

  &:active {
    transform: scale(0.98);
    box-shadow: none;
  }
`;

export const ButtonContent = styled.div`
  ${button}
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  ${button}
`;

export const LinkButton = styled(Link)`
  text-decoration: none;
  text-align: center;
  ${button}
`;
