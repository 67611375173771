import React from "react";
import Wrapper from "./Styles";
import NavItem from "./NavItem";

const SideNav = ({ active, navItems }) => {
  return (
    <Wrapper className="layout-sidenav">
      <div className="logo">Logo</div>
      <ul className="sidenav-menu">
        {navItems.map((item) => (
          <NavItem active={active} key={item.title} item={item} />
        ))}
      </ul>
    </Wrapper>
  );
};

export default SideNav;
