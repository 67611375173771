export const table = [
  {
    firstName: "Temeka",
    lastName: "Adams",
    dob: "11/11/2000",
    occupation: "manager",
    phoneNumber: "1234567890",
  },
  {
    firstName: "John",
    lastName: "Doe",
    dob: "07/07/1989",
    occupation: "developer",
    phoneNumber: "1234567890",
  },
  {
    firstName: "Patricia",
    lastName: "Albert",
    dob: "11/12/1999",
    occupation: "manager",
    phoneNumber: "1234567890",
  },
];
