import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainLayout from "../layout";
import Buttons from "../pages/buttons";
import Inputs from "../pages/inputs";
import Menu from "../pages/menu";
import Modal from "../pages/modal";
import Uploaders from "../pages/uploaders";
import { routes } from "./routes";
import Stepper from "../pages/stepper";
import Tables from "../pages/tables";
import Tabs from "../pages/tabs";
import Expand from "../pages/expand";
import Form from "../pages/forms";
import Parsers from "../pages/parsers";
import Csv from "../pages/csv";
import Pdf from "../pages/pdf";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <MainLayout>
          <Switch>
            <Route path={routes.BUTTONS} exact component={Buttons} />
            <Route path={routes.INPUTS} exact component={Inputs} />
            <Route path={routes.MENU} exact component={Menu} />
            <Route path={routes.UPLOADERS} exact component={Uploaders} />
            <Route path={routes.MODAL} exact component={Modal} />
            <Route path={routes.STEPPER} exact component={Stepper} />
            <Route path={routes.TABLES} exact component={Tables} />
            <Route path={routes.TABS} exact component={Tabs} />
            <Route path={routes.EXPAND} exact component={Expand} />
            <Route path={routes.FORMS} exact component={Form} />
            <Route path={routes.PARSERS} exact component={Parsers} />
            <Route path={routes.CSV} exact component={Csv} />
            <Route path={routes.PDF} exact component={Pdf} />
          </Switch>
        </MainLayout>
      </Switch>
    </Router>
  );
};

export default Routes;
