import { css } from "styled-components";
import { input } from "./input";

export const password = css`
  ${input}
  input {
    padding-right: 35px;
  }
  .icon {
    position: absolute;
    top: 50%;
    transform: translate(-10px, -50%);
    right: 0;
  }
`;
