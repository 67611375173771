import React from "react";
import styled from "styled-components";
import Accordion from "./Accordion";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & section {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;


const Menu = () => {
  return (
    <Wrapper>
      <section>
        <Accordion />
      </section>
    </Wrapper>
  );
};

export default Menu;
